<template>
	<div>{{ Breadcrumb }}</div>
</template>

<script>
export default {
	props: {
		breadcrumb: {
			type: Object,
			default: null,
		},
	},
};
</script>

<style></style>
