<template>
	<div class="xone-dataobjects">
		<template v-for="breadcrumb of routeBreadcrumbs" :key="breadcrumb.id">
			<div :id="`obj-${breadcrumb.id}`" class="xone-dataobject" v-show="breadcrumb === lastBreadcrumb">
				<Coll v-if="!breadcrumb.isSearch" :breadcrumb="breadcrumb"></Coll>
				<!-- TODO: definir comportamiento por defecto de un linkedsearch como objeto, así como su posible customización por atributos -->
				<LinkedSearchObject v-else :breadcrumb="breadcrumb"></LinkedSearchObject>
			</div>
		</template>
	</div>
	<div v-if="showBreadcrumbs" style="position: absolute; background: white; bottom: 0">
		<template v-for="breadcrumb in routeBreadcrumbs" :key="breadcrumb.id"
			><span>&nbsp;&nbsp;/&nbsp;&nbsp;{{ Breadcrumb.name }}</span></template
		><span>&nbsp;&nbsp;</span>
	</div>
</template>

<script>
// Vue
import { inject, watch, ComputedRef } from "vue";
import { useRoute, useRouter } from "vue-router";
// Components
import Coll from "../components/Coll.vue";
import LinkedSearchObject from "../components/LinkedSearchObject";
// Composables
import AppDataHandler, { Breadcrumb } from "../composables/AppDataHandler";

export default {
	components: {
		Coll,
		LinkedSearchObject,
	},
	setup() {
		const route = useRoute();
		const router = useRouter();

		/**
		 * @type {ComputedRef<Breadcrumb[]>}
		 */
		const routeBreadcrumbs = inject("routeBreadcrumbs");

		/**
		 * @type {ComputedRef<Breadcrumb>}
		 */
		const lastBreadcrumb = inject("lastBreadcrumb");

		/**
		 * @type {function():Promise<void>}
		 */
		const loginFunction = inject("loginFunction");

		const doLogin = () => {
			AppDataHandler.clearBreadcrumbs();
			// Create login XoneDataObject
			loginFunction();
		};

		// Change route depending on the last breadcrumb
		watch(
			() => lastBreadcrumb.value.id,
			() => {
				// if (!lastBreadcrumb.value)
				//   setTimeout(() => !lastBreadcrumb.value && doLogin(), 50);
				const type = lastBreadcrumb.value?.type;
				if (type !== "Login")
					router.push({
						name: "Coll",
						query: { id: lastBreadcrumb.value.id },
					});
			}
		);

		// Control history route modifications
		watch(
			() => route.query,
			(newValue) => {
				if (newValue.id && lastBreadcrumb.value.type === "Coll") AppDataHandler.clearBreadcrumbsFrom(newValue.id);

				if (route.name === "Login" && lastBreadcrumb.value.type !== "Login") doLogin();
				// if (route.name === "EntryPointFake") doLogin();
				if (route.name === "DeepLink") doLogin();
				if (route.name === "Preview") doLogin();
			}
		);

		return {
			routeBreadcrumbs,
			lastBreadcrumb,
			showBreadcrumbs: inject("showBreadcrumbs"),
		};
	},
};
</script>
